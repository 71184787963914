@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.location-online {
  background-color: $bg-grey;
  padding: 100px $sm-margin;
  display: flex;
  justify-content: center;

  &__container {
    max-width: $page-width;
    width: 100%;
  }

  &__location, &__online {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__online {
    margin-top: 100px;
  }

  &__heading {
    padding: 20px 0 50px;

    &--sub {
      font-size: 42px;
      line-height: 45px;
      font-weight: bold;
      color: $dark-blue;
      margin-bottom: 22px;
    }

    &--descr {
      font-size: $text-size;
      line-height: 28px;

      p {
        font-size: 20px;
      }

      ul {
        list-style: disc;
        padding-left: 40px;
        text-align: left;
      }
    }
  }

  &__container {
    &--online-text {
      padding-left: 50px;
      width: 50%;
    }

    &--location-text {
      padding-right: 50px;
    }
  }

  &__moreinfo {
    margin-top: 50px;
  }

  &__img {
    width: 50%;
    height: 100%;
  }

  @media only screen and (max-width: $sm-screen - 1) {
    @include responsive-padding(50px);
    &__location, &__online {
      text-align: center;
    }

    &__location {
      flex-direction: column-reverse;
    }

    &__online {
      flex-direction: column;
    }

    &__container {
      &--online-text {
        padding-left: 0;
        width: 100%;
      }

      &--location-text {
        padding-right: 0;
      }
    }

    &__heading {
      padding: 20px 0 50px;

      &--sub {
        margin-top: 30px;
        font-size: 34px;
        line-height: 40px;
      }

      &--descr {
        font-size: var(--s0);
        line-height: 26px;
      }
    }

    &__img {
      width: 100%;
    }
  }
}
