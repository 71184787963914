@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.page {
  &__tabs {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $dark-blue;
    color: $white;
  }

  &__tab {
    color: $white;
    font-size: 28px;
    font-weight: bold;
    padding: 36px 75px;
    margin: 0;
    cursor: pointer;
    opacity: 0.4;

    &:hover {
      opacity: 1;
      text-decoration: none;
      color: $white;
    }

    &--active {
      border-bottom: 7px solid $primary-green;
      opacity: 1;
    }

    @include sm-screen {
      font-size: var(--s1);
      padding: 35px 50px;
    }
  }
}
