@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "vars" as *;
// this bar is placed in the header, with relative positioning

.interactive-signup-bar {
  background-color: #1b3151;
  max-width: $page-width;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;


  &__container {
    width: 100%;
    background-color: #1b3151;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px $sm-margin;
  }

  &__item-wrapper {
    display: flex;
    align-items: center;
    width: 48%;
    padding: 8px 6px 8px 2px;

    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    font-weight: 800;
    color: $white;
  }

  &__item {
    font-size: var(--s-1);
    display: table;

    @include mobile-large {
      font-size: 13px;
    }
  }

  &__check {
    padding-right: 6px;
    font-size: var(--s1);
  }
}

@media only screen and (min-width: 640px) {
  .interactive-signup-bar {
    &__item {
      font-size: var(--s0);
    }

    &__check {
      font-size: 22px;
    }
  }
}

@media only screen and (min-width: 1092px) {
  .interactive-signup-bar {
    order: 2;
    left: 0;
    bottom: 0;
    margin: 0;

    &__item-wrapper {
      width: unset;
    }

    &__item {
      font-size: var(--s1);
      padding: unset;
    }

    &__check {
      font-size: 26px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .interactive-signup-bar {
    &__item {
      font-size: 23px;
    }
  }
}

@media only screen and (max-width: $sm-screen) {
  .interactive-signup-bar {
    &__container {
      bottom: -10px;
      left: 0;
    }
  }
}

@media only screen and (max-width: $xs-screen) {
  .interactive-signup-bar {

    &__container {
      display: none;
    }
  }
}

@media only screen and (max-width: $mobile-xs - 1) {
  .interactive-signup-bar {
    &__container {
      position: relative;
      bottom: -10px;
    }

    &__item {
      font-size: 10px;
    }
  }
}
