@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.features {
  background: inherit;
  margin-bottom: 30px;

  &__item {
    margin: 30px auto;
  }

  &__img {
    height: 110px;
  }

  &__heading {
    color: $primary-blue;
    font-size: 28px;
    line-height: 33px;
    font-weight: 700;
    margin-top: 28px;
  }

  @media only screen and (max-width: $sm-screen - 1) {
    &__item {
      margin: 12px auto;
    }

    &__img {
      height: 55px;
      width: 66px;
    }

    &__heading {
      font-size: var(--s0);
      line-height: 21px;
      font-weight: 400;
      margin-top: 16px;
    }
  }
}
