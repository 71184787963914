@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.for-teachers {
  @include layout('padding');

  .container-fluid {
    padding: 50px 0;
  }

  &__button {
    margin-top: 30px;
    border-color: $primary-blue !important;
    color: $primary-blue !important;
    background: $white !important;
  }

  &__heading {
    margin: 35px 0;
    font-size: 42px;
    line-height: 45px;
    color: $dark-blue;
    font-weight: bold;
  }

  &__text {
    font-size: $text-size;
    line-height: 28px;
    max-width: 820px;
    margin: 0 auto;
  }

  @media only screen and (max-width: $sm-screen - 1) {
    .no-padding-mobile {
      padding: 0;
    }

    &__heading {
      font-size: 28px;
      line-height: 33px;
    }

    &__text {
      display: none;
    }
  }
}
