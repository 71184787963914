@use "../../settings/all" as *;
@use "../../mixins/all" as *;

@use "sass:color";
@media only screen and (max-width: $xs-screen) {
  .selection-mobile {
    position: relative;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    margin: 60px auto 0;

    &__heading {
      font-size: 1.6em;
      font-weight: 600;
    }

    &__link {
      position: relative;
      display: block;
      padding: 6px;
      color: color.adjust($portal-blue, $lightness: -6%);
      font-size: 1.4em;

      &:hover, :active, :focus {
        text-decoration: none;
        background: $text;
        color: #fff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        font-weight: 600;
      }

      &.active {
        background: $portal-blue;
        color: #fff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        font-weight: 600;
      }
    }

    &__icon {
      position: absolute;
      right: 12px;
      top: 10px;
      display: none;
    }

    &__link.active {
      .selection-mobile__icon {
        display: inline-block;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .selection-mobile {
    display: none;
  }
}

