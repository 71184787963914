@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.about {
  padding: 50px 0 0;

  @include xxs-screen {
    margin-top: 20px;
  }

  &__heading {
    font-size: 42px;
    line-height: 45px;
    margin-bottom: 26px;
    font-weight: bold;
  }

  &__description {
    font-size: $text-size;
    line-height: 28px;
    font-weight: normal;
  }

  &__heading, &__description {
    color: $dark-blue;
  }

  &--teacherpage {
    padding: 50px;
  }

  @include xxs-screen {
    padding-top: 20px;

    &__heading-wrapper {
      display: flex;
    }

    &__heading {
      font-size: 28px;
      line-height: 33px;
    }

    &__description {
      font-size: var(--s0);
      line-height: 26px;

      br {
        display: none;
      }
    }
  }
}

.header__arrow {
  height: 60px;
}


