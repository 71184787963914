@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "vars" as *;
@forward "../student-signup/course";

$interactive-signup-completed-height: 92px;

.landingpage--interactive-signup {
  overflow-x: hidden;

  /* Keep the auto height of the container for interactive signup */
  .header__container {
    height: auto;
  }

  .react-interactive-signup {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.react-interactive-signup {
  margin: 20px 0 0 0;
  display: flex;
  z-index: 1999;
  width: 100%;
  position: relative;
  min-height: 200px;

  @media screen and (max-width: $mobile-screen) {
    margin-top: 0px;
  }

  &:not(.index) {
    transition: z-index ease-out 2s;
    margin-top: auto;
  }

  &.index {
    z-index: 1;
  }
}

.interactive-signup {
  display: flex;
  margin: 6px auto;
  transition: all 0.2s;
  z-index: 1101;
  max-width: 450px;
  max-height: 100%;

  &__row {
    padding: 16px 0;
  }

  &__top-nav {
    display: none;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &--centered {
    max-width: none;
  }

  &__screen {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 28px;
    background-color: $white;
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.5);
    z-index: 1100;
    padding: 24px 56px;
    min-height: 400px;
    min-width: 400px;
    overflow: auto;

    &--transitioning {
      box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.5),
      0 4px 20px 0 rgba($interactive-signup-neon-blue, 0.75);
      transform: scale(1.01, 1.01);
    }
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    color: $text;
    text-align: center;
    margin-bottom: 34px;


    &--centered {
      padding-top: 75px;
      text-align: center;
      width: 100%;
    }

    &--plain {
      padding: 0;
      margin: 0;
    }
  }

  &__choice-cards {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin: 0px 0px 17.4px;
  }

  &__choice-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 136px;
    height: 126px;
    border: unset;
    border-radius: 11px;
    background-color: $white;
    box-shadow: 0 1px 8px 0 rgba(128, 102, 102, 0.15);
    word-break: break-word;
    padding: 7px;

    &:first-child {
      margin-right: 36px;
    }

    &:hover, &--active {
      box-shadow: 0 0 0 3px $light-green;
    }
  }

  &__choice-card-text {
    font-size: var(--s1);
    font-weight: bold;
    color: $primary-blue;
    margin-bottom: 10px;
  }

  &__choice-card-image {
    height: 58px;
    width: 58px;
    flex: 1;
  }

  &__link {
    margin: 0px auto 9px;
    font-size: var(--s1);
    color: $text;
    text-decoration: none;

    a {
      text-decoration: underline;
    }


    &:hover, &:focus {
      color: $text;
    }
  }

  &__button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__navigation {
    background-color: transparent;
    font-size: 22px;
    border: unset;

    &--text {
      font-size: var(--s1);
      margin-right: 15px;
    }

    &--prev, &--next {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 12px;
    }

    &--prev {
      width: 20%;
      margin-right: 15px;
      border-radius: 11px;
      box-shadow: 0 1px 8px 0 rgba(128, 102, 102, 0.5);
      border: solid 0 $portal-blue;
      background-color: #ffffff;
      color: $portal-grey;

      &.mobile {
        display: none;
      }
    }

    &--next {
      width: 70%;
      justify-content: center;
      color: $white;
      background-color: $light-green;;
      box-shadow: $interactive-signup-box-shadow,
      0 1px 8px 0 rgba(183, 183, 183, 0.5);
      border-radius: 11px;

      &:disabled {
        background-color: $inactive-grey;
        cursor: not-allowed;
      }
    }

    &--highlight {
      transform: scale(1.04, 1.04);
    }
  }

  &__form-label {
    display: block;
    color: #777677;
    font-weight: bold;
    font-size: var(--s1);
    margin-top: 12px;
  }

  &__input {
    width: 100%;
    padding: $interactive-signup-input-padding;
    background-color: $white;
    border: unset;
    font-size: var(--s1);
    border-radius: 7.7px;
    box-shadow: 0 1px 6px 0 rgba(128, 102, 102, 0.15);

    &--small {
      height: 40px !important;
    }

    &--first {
      margin-top: unset !important;
    }

    &::placeholder {
      font-size: var(--s-1);
      color: #b8b6b6;
    }

    & + .interactive-signup__form-label {
      margin-top: 19px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin-top: 19px;
    cursor: pointer;

    input {
      margin: 0 8px 7px 0;
      width: 20px;
      height: 20px;
    }
  }

  &__warning {
    display: block;
    margin: 4px 0;
    color: $dark-orange;
  }

  &__progress {
    // box model
    height: 42px;
    border-radius: 12px;
    background-color: #cdcccc;
    z-index: 1;
    display: flex;
    left: 16px;
    right: 10px;
    align-items: center;
    bottom: 19px;
    box-shadow: 1px 2px 2px 0 rgba($black, 0.2);

    &--completed {
      height: $interactive-signup-completed-height;
    }

    &-wrapper {
      margin-top: 30px;
      position: relative;
    }
  }

  &__progress-bar {
    position: absolute;
    height: 42px;
    background-color: rgba($light-green, 0.8);
    z-index: 2;
    border-radius: 15px;

    &--completed {
      background-color: #71d38e;
      height: $interactive-signup-completed-height;
    }
  }

  &__progress-text {
    // box model
    height: 42px;
    background-color: transparent;
    z-index: 3;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    // text style
    font-size: var(--s1);
    font-weight: bold;
    color: $white;
    line-height: 24px;
    text-align: center;
  }

  &__selected-courses {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;

    &:empty {
      display: none;
    }
  }

  &__autocomplete-wrapper {
    position: relative;
    margin-bottom: 12px;

    & > div {
      width: 100%;
    }
  }

  &__selected-course {
    display: flex;
    align-items: center;
    border-radius: 9.6px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    background-color: $interactive-signup-neon-blue;
    padding: 4px 12px 4px 16px;
    margin: 3px;
    color: #f8f8f8;
    font-size: var(--s0);
    font-weight: bold;

    &-wrapper {
      display: flex;
    }
  }

  &__selected-course-cancel {
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      transform: scale(1.05, 1.05);
    }
  }

  &__completed {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__completed-emoji {
    font-size: 40px;
    margin: 25px 0;
  }

  &__completed-link {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    font-size: var(--s1);
    font-weight: bold;
    padding: 10px;

    &, &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__bottom-actions {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .btn__quote {
      margin: 20px 0;
    }
  }
}

@include sm-screen {
  .interactive-signup {

    &__row {

      .padding-sm {
        /*Align post code field*/
        padding-top: 19px;
      }
    }
  }
}

@include xxs-screen {
  .interactive-signup {
    margin: 0;
    padding: 0px;

    &__wrapper {
      position: relative;
    }

    &__row {
      padding-bottom: 0;

      div:first-of-type {
        margin-bottom: 16px;
      }

      .padding-sm {
        /* Align post code and name fields */
        padding-top: 0;
      }
    }

    &__checkbox {
      input[type='checkbox'] {
        width: 40px;
        height: 40px;
      }
    }

    &__button-row {
      margin: 40px 0;
    }

    &__top-nav {
      display: block;
      height: 40px;

      button {
        position: absolute;
        left: 0;
      }
    }

    &__navigation {
      &--next {
        width: 100%;
        height: 60px;

        span {
          font-weight: 700;
        }

        i {
          font-weight: 400;
          margin-top: 4px;
        }
      }

      &--prev {
        display: none;

        &.mobile {
          display: block;
          background: transparent;
          box-shadow: none;
          width: auto;

          i {
            font-weight: 400;
            font-size: 30px;
          }
        }
      }
    }

    &__title {
      font-size: var(--s1);
      margin-bottom: 16px;
      height: auto;
    }

    &__form-label {
      font-size: 20px;
    }

    &__input {
      padding: 17px 20px;
    }

    &__screen {
      padding: 15px;
      max-width: 330px;
      overflow-y: auto;
    }

    &--focused {
      width: 100%;
      height: 100%;
      padding: 0;
      max-width: 100%;
      overflow-y: visible;

      .interactive-signup__wrapper {
        position: relative;
        z-index: 1101;
        top: 0px;
        bottom: 60px;
        left: 0;
        right: 0;
        padding: 0px;
        overflow-y: auto;
        background: #F8F8F8;
        flex-direction: column;
        justify-content: start;

        .interactive-signup__screen {
          overflow: visible;
          width: 100%;
          padding: 0px 40px 40px;
          background: transparent;
          box-shadow: none;

          &--focused {
            border-radius: 0;
            max-width: 100%;
            min-width: 0;
          }
        }
      }
    }

    &__form {
      margin: 0;
    }

    &__button-row {
      padding: 0;
      flex-shrink: 0;
    }


    &__title--plain {
      margin-top: 50px;
    }

    &__input {
      -webkit-appearance: none;
    }

    &__form-label:not(:first-child) {
      margin-top: 22px;
    }

    &__choice-card {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@include xs-screen {
  .interactive-signup {
    &__choice-card-text {
      font-size: var(--s-1);
    }
  }
}
