@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.signup-course {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__list {
    display: flex;
    gap: 6px;
    margin: 0;
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--s0);
    border: 1px solid $border-color;
    padding: $button-padding;
    min-height: 40px;
    width: fit-content;
    border-radius: 30px;
    line-height: 1;
    background-color: white;
    transition: all 0.2s ease-in;

    a {
      z-index: 0;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: $primary-green;
      cursor: pointer;
      color: white;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  &__list-icon {
    width: 16px !important;
    height: 16px;
    border-radius: 50%;
    margin: 0 8px 4px 0;
  }

  &__text {
    font-size: 22px;
    font-weight: 500;

    &--small {
      font-size: var(--s0);
    }
  }

  @include mobile-screen {
    &__list {
      flex-wrap: unset;
      overflow-x: auto;
    }
  }
  @include medium-screen {
    &__list {
      flex-wrap: unset;
      overflow-x: auto;
    }
  }
}

.course-select {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #FDF9F6;
  padding: 36px;
  border-radius: 20px;
  box-shadow: 0 0 30px 0 #0000001A;
  min-width: 700px;
  width: 100%;
  top: 100px;
  @include mobile-screen {
    top: -50px;
    min-height: 200px;
  }
  position: absolute;

  &__wrapper {
    display: flex;
  }

  @include sm-screen {
    //margin-top: -100px;
  }
  @include mobile-screen {
    min-width: unset;
    width: 100%;
    padding: 15px;
  }
}
