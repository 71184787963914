@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.levels {
  padding: 100px $sm-margin;
  background-color: $bg-grey;
  display: flex;
  justify-content: center;

  &__container {
    max-width: 1440px;
    width: 100%;
    align-self: center;
  }

  &__header {
    text-align: center;
    font-size: 42px;
    line-height: 45px;
    font-weight: bold;
    margin-bottom: 50px;
  }

  video {
    width: 100%;
  }

  &__video-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 20px;

    img {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__level {
    display: flex;
    flex-direction: column;
    width: 25%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 20px;
  }

  &__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 33px;
    color: $primary-blue;
  }

  &__text {
    font-size: $text-size;
    line-height: 28px;
    flex: 1 0 auto;
  }

  .btn.btn__cta {
    background: transparent;
    margin: 26px 0;
    align-self: flex-start;
  }

  &__testimonial {
    font-size: $text-size;
    font-style: italic;
    color: $primary-blue;
  }

  .kmo-logo {
    display: none;
  }

  @media only screen and (max-width: $sm-screen - 1) {
    @include responsive-padding(50px);

    &__video-wrapper, &__text, &__testimonial {
      display: none;
    }

    &__header {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 20px;
    }

    &__list {
      flex-direction: column;
      align-items: center;
    }

    &__level {
      width: 100%;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 20px;
    }

    .btn.btn__cta {
      background: $primary-green;
      border-color: $primary-green;
      margin: 0;
      align-self: center;
    }

    .kmo-logo {
      display: block;
      height: 40px;
      width: auto;
      align-self: center;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 1437px) {
    &__title {
      height: 65px;
    }
  }

  @include mobile-screen {
    text-align: center;
  }
}

