@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "../interactive-signup/vars" as *;
// TODO figure out how to give custom styles precedence, without using !important

.bh-select {

  &__container {
    margin-bottom: 1em;
    border-radius: 11px !important;
    border: none;
  }

  &__control {
    border-radius: 11px !important;
    border-style: none !important;
    padding: $interactive-signup-input-padding !important;
    box-shadow: $interactive-signup-box-shadow !important;

    &--is-focused {
      border: none;
    }

    @include mobile-screen {
       padding: 4px 8px !important;
    }
  }

  &__value-container {
    padding: 0 !important;

    @include xxs-screen {
      padding: 17px 12px !important;
    }
  }

  &__option {
    color: $text !important;
    font-size: 18px !important;
    font-weight: bold;
    cursor: pointer !important;

    &:first-child {
      border-top-left-radius: 11px;

      @media screen and (max-width: $xs-screen) {
        border-top-right-radius: 11px;
      }
    }

    &--is-selected, &:hover {
      background-color: rgba(1, 182, 215, 0.5) !important;
    }
  }

  &__menu {
    border: none !important;
    box-shadow: $interactive-signup-box-shadow !important;
    border-radius: 11px !important;
    margin-top: 4px;
  }


  &__menu-list {
    padding-top: 0 !important;
    overflow: scroll !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__placeholder {
    font-size: var(--s1);
    color: #ccc !important;

    @include mobile-screen {
      font-size: var(--s-1);
    }
  }

  &__single-value {
    font-size: var(--s1);
    color: $text !important;
  }
}
