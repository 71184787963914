@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.about-teachers {
  text-align: center;
  background: $bg-grey;
  padding: 50px $sm-margin;
  display: flex;
  justify-content: center;

  &__container {
    max-width: $page-width;
    width: 100%;
  }

  &__items {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    .halfwidth {
      width: 60%;
    }
  }

  &__item-img {
    padding: 10px 0;
    height: 150px;
  }

  &__item-description {
    font-size: $text-size;
    line-height: 28px;
  }

  &__item-heading {
    font-size: $text-size;
    line-height: 28px;
    font-weight: bold;
    color: $primary-blue;
    padding: 20px;
    margin: 0 auto;
    min-height: 95px;
  }

  @include xxs-screen {
    &__item-heading, &__item-description {
      font-size: var(--s0);
      line-height: 26px;
    }

    &__item-description {
      margin-bottom: 40px;
    }
  }
}
