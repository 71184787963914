@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.search {
  width: 100%;
  color: #fff;

  & + .levels {
    margin-top: -40px;
  }
}
.search__shape {
  z-index: 2;
  background: $portal-blue;
  display: block;
  height: 80px;
  margin: 0 auto;
  max-width: 1100px;
  position: relative;
}
.search__shape:before {
  border-top: 40px solid transparent;
  border-right: 30px solid $portal-blue;
  border-bottom: 40px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  left: -30px;
  width: 0;
}
.search__shape:after{
  border-top: 40px solid transparent;
  border-left: 30px solid $portal-blue;
  border-bottom: 40px solid transparent;
  content: "";
  height: 0;
  top:0;
  position: absolute;
  right: -30px;
  width: 0;
}

.search__form{
  cursor:text;
  display: flex;
  margin-left: 70px;
  input{
    width:30%;
    padding: 0 10px;
    margin:18px;
    font-size: 1.6em;
    color: $text;
    outline-color:#fff;
  }
  input[type="submit"]{
    width:30%;
    border: 4px solid #fff;
    border-radius: 2px;
    font-weight: 600;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    background-color: $portal-blue;
    margin-left: 2.4em;
  }
  input[type="submit"]:hover, input[type="submit"]:active {
    background-color: rgb(255,255,255);
    color: $portal-blue;
    text-shadow: none;
  }
  &__text{
    margin: 24px 16px;
    font-size: 1.6em;
    font-weight: 600;
  }
}

.search__icon {
  position: absolute;
  left: -18px;
  bottom: 14px;
}

@include not-large-screen {
  .search__shape:before, .search__shape:after{
    border:none;
  }
  .search__icon, .form__text{
    display:none;
  }
  .search__shape{
    height:auto;
  }
  .search{
    height: auto;
  }
  .search__form__text{
    display: none;
  }
  .search__form{
    flex-direction: column;
    padding: 20px;
    margin:auto;
    background-color: $portal-blue;
    input{
      width:100%;
      margin: 2px 0;
    }
    input[type="submit"]{
      margin: 0 auto;
      width: 180px;
    }
  }
}
@media (max-width: $xs-screen) {
  .search__shape {
    margin-left: 20px;
    margin-right:20px;
  }

}
