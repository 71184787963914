@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.pricing-table {
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  color: $black;
  &__columnheader {
    font-size: var(--s0);
    font-weight: bold;
    text-align: center !important;
  }
  &__rowlabels {
    padding: 6px;
  }
  &__rowlabel {
    text-align: left !important;
    font-size: var(--s-1);
    font-weight: bold;
    margin: 0;
    &--small {
      font-size: 10px;
      font-weight: normal;
    }
  }
  &__row {
    height: 43px;
    &--even-coloured:nth-child(even) {
      background-color: rgba(1, 182, 215, 0.05);
    }
  }
  &__body {
    border-top: 8px solid #10c8e9;
  }
}
