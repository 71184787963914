@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "vars" as *;
@use "sass:color";
$interactive-signup-overlay-close-icon-color: #EAEAEA;

.interactive-signup-overlay {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0;
  z-index: 0;

  &__focused {
    margin: 0;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1100;

    &--dark {
      background-color: rgba(74, 74, 74, 0.7);
    }
  }

  &__close-icon {
    position: fixed;
    top: 7px;
    right: 30px;
    color: $interactive-signup-overlay-close-icon-color;
    z-index: 1103;
    cursor: pointer;
    font-weight: 400 !important;

    &--mobile {
      display: none;
    }

    &:hover, &:active {
      color: color.adjust($interactive-signup-overlay-close-icon-color, $lightness: 10%);
    }
  }

  @include xxs-screen {
    margin: 0;
    width: 100%;
    height: 100%;
    justify-content: center;

    &__close-icon {
      position: absolute;
      right: 7px;
      font-size: 2.5em !important;
      color: #A2A2A2;
      display: none !important;

      &--mobile {
        display: block !important;
      }
    }

    &__focused {
      // Cover navbar
      z-index: 9999;
    }
  }

  @include mobile-screen {
    margin: 0 auto;
  }
}


