@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.steps {
  width: 100%;
  padding: 100px $sm-margin;
  display: flex;
  justify-content: center;

  &__container {
    max-width: $page-width;
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info {
    max-width: 50%;

    &:nth-of-type(odd) {
      margin-right: 50px;
    }

    &:nth-of-type(even) {
      margin-left: 50px;
    }
  }

  &__title {
    text-align: center;
    font-size: 42px;
    color: $dark-blue;
    font-weight: bold;
    margin: 0;
  }

  &__header {
    font-size: 28px;
    line-height: 33px;
    font-weight: bold;
    color: $primary-blue;
  }

  &__text {
    font-size: $text-size;
    line-height: 28px;
  }

  &__img-wrapper {
    max-width: 600px;

    img {
      width: 100%;
    }
  }

  @include sm-screen {
    &__header {
      font-size: 22px;
      line-height: 30px;
    }

    &__text {
      font-size: var(--s1);
    }

    &__img-wrapper:nth-of-type(odd) {
      margin-right: 40px;
    }
  }


  @media only screen and (max-width: $sm-screen - 1) {
    @include responsive-padding(50px);
    &__title {
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    &__row {
      flex-direction: column;
    }

    &__header {
      font-size: 28px;
      line-height: 33px;
    }

    &__info {
      max-width: 100%;
      margin-bottom: 30px;
      text-align: center;


      &:nth-of-type(odd) {
        margin-right: 0;
      }

      &:nth-of-type(even) {
        margin-left: 0;
      }
    }

    &__img-wrapper {
      order: -1;

      &:nth-of-type(odd) {
        margin-right: 0;
      }
    }
  }
}
