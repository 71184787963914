@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "vars" as *;
$select-course-item-highlight-color: $tag-blue !default;

.select-course-menu {
  padding: 11px 7px 8px;
  border-radius: 7.7px;
  box-shadow: 0 1px 6px 0 rgba(128, 102, 102, 0.15);
  background: white;
  position: absolute;
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
  z-index: 9999;

  &:empty {
    display: none;
  }

  &__item {
    padding: 10px 14px;
    font-family: Nunito;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }

    &--highlight {
      background: rgba($select-course-item-highlight-color, 0.5);
    }
  }

  &__item-bit {
    font-size: var(--s0);
    padding: 0;

    &--highlight {
      color: $select-course-item-highlight-color;
    }
  }
}
