// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker|Jockey+One|Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap');

$default-family: 'Nunito', "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
$jocky: 'Jockey One';
$header-font: 'Moranga', $default-family;


@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Black It.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Bold It.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Light It.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Medium It.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Regular It.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Medium It.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('../fonts/Latinotype - Moranga Regular It.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
