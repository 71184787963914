$mobile-xs: 360px;
$mobile-screen: 480px;
$xs-screen: 767px;
$sm-screen: 1024px;
$tablet-vertical: 1025px;
$md-screen: 1280px;
$l-screen: 1440px;
$xl-screen: 1600px;

$xl-margin: 14%;
$sm-margin: 5%;

$text-size: var(--s1);
$page-width: 140ch;

$button-padding: 10px 16px;
