@use "../settings/all" as *;

@mixin large-screen() {
  @media only screen and (min-width: $tablet-vertical + 1px) {
    @content;
  }
}

@mixin not-large-screen() {
  @media only screen and (max-width: $tablet-vertical) {
    @content;
  }
}

@mixin medium-screen() {
  @media only screen and (min-width: $xs-screen + 1px) {
    @content;
  }
}

@mixin mobile-screen() {
  @media only screen and (max-width: $xs-screen) {
    @content;
  }
}

@mixin xs-screen() {
  @media only screen and (max-width: $mobile-xs) {
    @content;
  }
}

@mixin xxs-screen() {
  @media only screen and (max-width: $mobile-screen) {
    @content;
  }
}

@mixin sm-screen() {
  @media only screen and (max-width: $sm-screen) {
    @content;
  }
}

@mixin md-screen() {
  @media only screen and (max-width: $md-screen) {
    @content;
  }
}

@mixin l-screen() {
  @media only screen and (min-width: $l-screen) and (min-height: 800px) {
    @content;
  }
}

@mixin xl-screen() {
  @media only screen and (min-width: $xl-screen) and (min-height: 900px) {
    @content;
  }
}

@mixin short-screen() {
  @media only screen and (min-width: 900px) and (max-height: 800px) {
    @content;
  }
}

// iPhone 12
@mixin mobile-large() {
  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

