@use "sass:color";

$white: #ffffff;
$black: #312F2F;
$gray: #f3f3f4;
$darker-gray: color.adjust($gray, $lightness: -5%);

$blue: #00B7D6;
$red: #DD5757;
$yellow: #F5A623;
$green: #1DB69E;


// New colors
$dark-blue: #1B3151;
$primary-blue: #30649A;
$primary-green: #71D38E;
$secondary-green: #15b790;
$bg-blue: #C5E5FF;
$bg-grey: #F1ECEB;
$text-dark: #4E4E4E;


$text: #1b3151;
$light-text: #676a6c;
$color-text: $text !default;
$text-muted: #4E4E4E;

$color-nav: #009db7;
$light-green: #5ad07d;
$lighter-green: #80D997;
$pickford: #CAF2D6;
$input-background: #eaeaea;
$portal-blue: $dark-blue;
$portal-grey: #979797;
$grey: $text;
$light-grey: rgba($grey, 0.8);
$light-gray: #D1DADE;
$inactive-grey: $light-gray;
$broken-white: #f7f7f7;
$formset-green: #2ebc9f;
$formset-green-light: #41d9bb;
$lightish-green: #76dd74;
$dark-orange: #f99c17;

$charcoal-grey: #323648;
$background: #f1f1f1;
$badge-color: #ff7058;
$text-color: $text;
$light-blue: #EBF2F5;
$light-orange: #fab512;


/* Tag colors */
$tag-green: #2ebc9f;
$tag-blue: #01b6d7;
$tag-orange: #f58a14;
$tag-purple: #9972B6;
$tag-gray: gray;
$tag-red: #ff3333;
$border-color: #e5e6e7;

/* Legacy colors */
$navy: #1ab394; // Primary color
$dark-gray: #c2c2c2; // Default color
$lazur: #23c6c8; // Info color
$green: #46BCA4;
$bh-yellow: #F8B418;

$label-badget-color: #5E5E5E;
$border-color: #e7eaec; // IBox border
$nav-bg: #2F4050;
$nav-text-color: #a7b1c2;

//.form-control border color
$dark-border-color: #ccc;

$shadow: rgba(0, 0, 0, 0.25);

$snow: #FDF9F6;
$transparent: rgba(0, 0, 0, 0);
