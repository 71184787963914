@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "vars" as *;
.validation-errors {

  border-radius: 11px;

  &__error {
    font-size: var(--s0);
    font-weight: bold;
    color: $red;
  }
}
