@use "../settings/all" as *;
@use "screen-sizes";

@mixin layout($prop: 'margin') {
  #{$prop}: 0 $sm-margin;

  @include screen-sizes.xl-screen {
    #{$prop}: 0 $xl-margin;
  }
}

@mixin responsive-padding($padding: 0) {
  padding: $padding $sm-margin;

  @include screen-sizes.xl-screen {
    padding: $padding $xl-margin;
  }
}

@mixin responsive-margin($margin: 0) {
  margin: $margin $sm-margin;

  @include screen-sizes.xl-screen {
    margin: $margin $xl-margin;
  }
}

@mixin ribbon-border-after($font-size) {
  @if $font-size > 20 {

  }
  border-width: #{$font-size}px 17px #{$font-size}px 0px;
}

@mixin ribbon-border-before($font-size) {
  border-width: #{$font-size}px 0px #{$font-size}px 17px;
}


@mixin subheading($font-size) {
  font-size: #{$font-size}px;

  @if $font-size > 20 {
    &::before {
      left: -17px;
      border-width: #{$font-size}px 0px #{$font-size}px 17px;
    }

    &::after {
      right: -17px;
      border-width: #{$font-size}px 17px #{$font-size}px 0px;
    }
  } @else {
    &::before {
      left: -17px;
      border-width: #{$font-size + 2}px 0px #{$font-size + 2}px 17px;
    }

    &::after {
      right: -17px;
      border-width: #{$font-size + 2}px 17px #{$font-size + 2}px 0px;
    }
  }
}
