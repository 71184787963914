@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.request-info {
  display: flex;
  justify-content: center;
  padding: 100px 0;

  &__container {
    display: flex;
    justify-content: center;
    max-width: $page-width;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;

    .btn {
      align-self: flex-start;
      margin-top: 20px;
    }
  }

  &__header {
    font-size: 28px;
    line-height: 33px;
    font-weight: bold;
    color: $dark-blue;
    max-width: 400px;
  }

  @include xxs-screen {
    display: none;
  }
}
