@use "../settings/all" as *;


$floating-label-color: #898989 !default;
$floating-label-focus-color: $dark-blue !default;
$float-label-background: $white;

@mixin floating-label() {

  &:focus + label[placeholder]:before {
    color: $floating-label-focus-color;
  }

  &:focus + label[placeholder]:before,
  &:valid + label[placeholder]:before {
    transition-duration: $fast-duration;
    // transform: translate(0, -0.5em) scale(0.9, 0.9);
  }

  &:invalid + label[placeholder][alt]:before {
    content: attr(alt);
  }

  & + label {
    @include float-label-styles;
  }
}

@mixin float-label-before() {
  &:before {
    content: attr(placeholder);
    display: inline-block;
    position: relative;
    top: -8px;
    margin: 0 6px;
    padding: 0 2px;
    white-space: nowrap;
    transition: 0.3s ease-in-out;
    background: $float-label-background no-repeat center;
    background-size: 100% 5px;
  }
}

@mixin float-label-styles() {

  &[placeholder] {
    position: absolute;
    top: 10px;
    margin: 0;
    @include float-label-before;
  }
}

// or, explicit style
.floating-label {
  &--focus {
    &:before {
      color: $floating-label-focus-color !important;
    }
  }

  &--float,
  &--focus {
    &:before {
      transition-duration: $fast-duration;
    }
  }

  &--modal {
    &::before {
      transform: translate(0, -1.5em) scale(0.9, 0.9) !important;
    }
  }

  &--modal {
    &::before {
      transform: translate(0, -1em) scale(0.9, 0.9) !important;
      color: $text-color;
    }
  }
}
