@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "vars" as *;
// vendor of react-select overrides... ew

.education-level-select {
  box-shadow: $interactive-signup-box-shadow;
  border-radius: 11px;
  margin-bottom: 1em;

  .Select-control {
    border: none;
    padding: $interactive-signup-input-padding;

    .Select-value {
      // box model
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // font style
      color: $text;
      font-size: var(--s1);
      font-weight: bold;
    }
  }

  .Select-menu-outer {
    border: none;
    box-shadow: $interactive-signup-box-shadow;
    border-radius: 11px;
  }

  &__option {
    color: $text;
    font-size: var(--s1);
    font-weight: bold;
  }
}
