@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.testimonials {
  display: flex;
  background-color: $bg-grey;
  justify-content: center;
  padding: 75px $sm-margin;

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: $page-width;
    width: 100%;
  }

  .carousel-mobile {
    display: none;
  }

  .carousel-desktop {
    display: block;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;

    .btn {
      align-self: flex-start;
      background: transparent;
    }
  }

  &__list {
    max-height: 800px;
    overflow: auto;
    padding: 15px;
    margin-right: -15px;
  }

  &__header {
    font-size: 42px;
    line-height: 45px;
    color: $dark-blue;
    font-weight: bold;
  }

  &__card {
    display: flex;
    justify-content: space-between;
    max-width: 715px;
    background: white;
    padding: 20px 30px;
    margin-bottom: 30px;
    border-radius: 38px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25), 4px 4px 12px rgba(0, 0, 0, 0.25);
  }

  &__media, &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__body {
    p {
      font-size: $text-size;
    }
  }

  &__media {
    justify-content: center;
    flex-shrink: 0;
    width: 150px;
    text-align: center;
    margin-right: 14px;
  }

  &__avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 12px;
    align-self: center;
    object-fit: cover;
  }

  &__author {
    font-size: var(--s1);
    font-weight: bold;
  }

  &__punchline {
    font-size: var(--s0);
    line-height: 25px;
    text-transform: uppercase;
    margin: 0;
  }

  @media only screen and (max-width: $sm-screen - 1) {
    &__container {
      flex-direction: column;
    }
    flex-direction: column;
    padding: 50px 0;

    .carousel-mobile {
      display: block;
      width: 90%;
      margin-left: 10%;
      height: 100%;

      .swiper-wrapper {
        padding: 15px;

        & + .swiper-pagination {
          width: 90%;
        }
      }
    }

    .carousel-desktop {
      display: none;
    }

    &__heading {
      align-items: center;

      .btn {
        margin: 30px auto;
        align-self: center;
      }
    }

    &__header {
      font-size: 35px;
      line-height: 40px;
      text-align: center;
    }

    &__card {
      flex-direction: column;
      align-items: center;
      width: 255px;
      height: auto !important;
      justify-content: flex-start;
    }

    &__media {
      margin-bottom: 16px;
    }

    &__body {
      p {
        font-size: var(--s0);
        line-height: 26px;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .carousel-mobile {
      width: 100%;
      margin-left: 0;
    }
  }
}
