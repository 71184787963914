@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.loader {
  &__wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    padding: 48px;
    border-radius: 4px;
  }

  &__icon {
    margin-left: 12px;
    animation: fa-spin 2s linear infinite;
  }

  &__text {
    font-size: 24px;
  }
}

.loader {
  display: none;
}

.htmx-request.loader {
  display: flex;

  & + .hide-on-load {
    display: none;
  }
}
