@use "../../settings/all" as *;
@use "../../mixins/all" as *;

$trans-white: rgba(255, 255, 255, 0.85);
$full-white: rgba(255, 255, 255, 1);


.banner {
  text-align: center;
  position: relative;

  + .navbar-fixed-top {
    margin-top: 40px;
  }

  + .navbar-fixed-top + .header {
    height: 95vh;

    @media screen and (max-width: 320px) {
      max-height: none;
    }
  }

  a {
    background-color: $yellow;
    color: $trans-white;
    font-size: 1.1em;
    letter-spacing: 0.03em;
    font-family: $jocky;
    padding: 6px 2%;
    width: 100%;
    height: 100%;
    display: block;
    transition: linear 0.1s;
  }

  a:hover, a:active, a:focus {
    text-decoration: none;
    background-color: $portal-blue;
    color: $full-white;
  }

  .underline {
    text-decoration: underline;
  }
}

.hire-teachers {
  .for-teachers__button--hire {
    text-transform: uppercase;
    font-family: 'Permanent Marker', cursive;
    font-size: 0.78em;
    color: $trans-white;
    margin-left: 20px;
    margin-bottom: 1px;
    border: solid $trans-white;
    transform: rotate(2deg);
    padding: 0 5px;
  }

  a:hover, a:active, a:focus {
    .for-teachers__button {
      &--hire {
        color: $full-white;
        border: solid $full-white;
        transform: rotate(-1deg);
      }
    }
  }
}

.banner.corona {
  a {
    font-size: 20px;
  }
  @media screen and (max-width: 932px) {
    + .navbar-fixed-top {
      margin-top: 65px;
    }
  }

  @media screen and (max-width: 482px) {
    + .navbar-fixed-top {
      margin-top: 45px;
    }

    a {
      font-size: 12px;
    }
  }

}

@media only screen and (max-width: $xs-screen) {
  .hire-teachers {
    &--lg {
      display: none;
    }
  }
}
