@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.student-summaries {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--s1) 0;
  position: relative;


  .swiper-container {
    width: 100%; /* Ensure it stretches to the container width */
    overflow: hidden; /* Prevent visible overflow */
  }

  .swiper-wrapper {
    display: flex;
    width: inherit; /* Let Swiper handle the width calculation */
  }

  h2.student-summaries__header {
    font-weight: 500;
    font-size: var(--s4);
    margin-bottom: var(--s4);
    width: 100%;
    text-align: left;
  }

  &__container {
    width: 100%;
    height: 100%;
    padding-bottom: var(--s3) !important;
    max-width: $page-width;
    margin-bottom: var(--s-4);
  }

  &__card {
    background-color: $bg-grey;
    border-radius: var(--s-1);
    mix-blend-mode: normal;
    padding: var(--s4);
    height: auto !important;

    &.swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__title {
    font-family: $header-font;
    font-size: var(--s1);
    font-weight: bold;
    margin-top: var(--s-1);
    color: $dark-blue;

    &--large {
      font-size: var(--s4);
    }
  }

  &__text {
    &--large {
      font-size: var(--s1);
    }
  }

  &__next {
  }

  &__footnote {
    font-size: var(--s-1);
    width: 100%;
    margin-block-end: var(--s0);
  }

  &__see-more {
    text-decoration: underline;
    cursor: pointer;
  }

  &__modal {
    .modal-content {
      padding: var(--s2) var(--s3);
      border-radius: var(--s0);
    }
  }

  .btn__cta {
    font-size: var(--s0);
    font-weight: 700;
    padding: var(--s-1) var(--s0);
    display: flex;
    align-items: center;
  }


  .swiper-pagination-bullet-active {
    background-color: $primary-green !important;
  }

}

.modal-open .student-summaries__modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: none !important;
}

