@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "vars" as *;
.interactive-signup-textarea {
  // box model
  width: 100%;
  min-height: 200px;
  height: 100%;
  border-radius: 11px;
  border: none;
  padding: 10px;
  box-shadow: $interactive-signup-box-shadow;
  -webkit-appearance: none;

  // font
  font-size: var(--s1);
  font-weight: bold;

  &::placeholder {
    color: #b8b6b6;
  }
}
