@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.helloprof-landing {

  &__selection-box {
    margin-left: 5%;
    margin-top: 5%;
    width: 400px;
  }
  &__selection-one{
    float: left;
  }

  &__selection-two{
    float: right;
    margin-left: 10px;
  }
}


@media only screen and (max-width: $mobile-screen) {
  .helloprof-landing {
    &__selection-box{
      width: auto
    }
    &__selection-one{
      margin: auto;
      float: none;
      clear: both;
    }

    &__selection-two{
      margin: auto;
      margin-top: 10px;
      float: none;
      clear: both;
    }
  }
}
